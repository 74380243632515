import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import SEO from '../components/seo';
import Footer from '../components/footer';
import './code.scss';

const CodeTemplate = (props) => {
  return (
    <Layout>
      <SEO title={props.data.contentfulCode.seoTitle} description={props.data.contentfulCode.seoDescription} keywords={props.data.contentfulCode.seoKeywords} />
      <Nav />
      <MobileNav />
      <div className='hero-header'>
        <h1 className='__title'>{props.data.contentfulCode.title}</h1>
        <img className='__hero-image' src={props.data.contentfulCode.featuredImage.fluid.src} alt="hero" />
      </div>
      <div className='code-wrapper'>
        <div className='__content'>
          <div dangerouslySetInnerHTML={
            {__html: `${props.data.contentfulCode.content.childMarkdownRemark.html}`}
          } />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default CodeTemplate;


export const query = graphql`
 query CodeTemplate($id: String!) {
   contentfulCode(id: {eq: $id}) {
     title
     id
     slug
     content {
       childMarkdownRemark {
         html
       }
     }
     seoTitle
     seoDescription
     seoAuthor
     seoKeywords
     seoImage {
       fluid(maxWidth: 1200, quality: 100) {
         ...GatsbyContentfulFluid
         src
       }
     }
     featuredImage {
       fluid(maxWidth: 1200, quality: 100) {
         ...GatsbyContentfulFluid
         src
       }
     }
   }
 }
`